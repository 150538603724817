import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import navigate from gatsby
import * as styles from './phonepe.module.scss';

export default function CompanyEventPostApplicationPage(props) {
  const getCompanyPage = (company) => {
    switch (company) {
      case 'PHONEPE':
        return (
          <div className="relative min-h-screen  bg-phonepe-primary-purple-600 p-5 text-center text-white">
            <div className="mb-14 flex  items-center justify-center md:mb-5 lg:justify-between">
              <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../../../images/phonepe/Logo.png"
                placeholder="blurred"
                width={80}
                alt="PhonePe"
              />
              <div className="absolute top-8 right-5 z-10 p-3 transition-all lg:left-0 lg:right-0 ">
                <a
                  href="/?utm_source=phonepe&utm_medium=post_application&utm_campaign=phonepe-tech-scholars-program"
                  target="_self"
                >
                  <FontAwesomeIcon
                    className=" cursor-pointer  opacity-50 hover:opacity-25"
                    size="2x"
                    icon={['fas', 'times']}
                  ></FontAwesomeIcon>
                </a>
              </div>
              <div className="hidden justify-center lg:flex">
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="lazy"
                  src="../../../images/phonepe/Partner Logo.png"
                  placeholder="blurred"
                  width={300}
                  alt="PhonePe"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="lg:max-w-[400px]">
                <div className="reveal mb-8">
                  <h1 className={`reveal__content ${styles.title}  `}>
                    Thank You for your application!{' '}
                  </h1>
                </div>

                <div className="reveal  mb-10">
                  <p className="reveal__content font-light leading-relaxed">
                    We will communicate the next steps about your application
                    over email.
                  </p>{' '}
                </div>

                <div className="mb-10">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/phonepe/Hero.png"
                    placeholder="blurred"
                    width={800}
                    alt="Hero"
                  />
                </div>
                <div className="lg:hidden ">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/phonepe/Partner Logo.png"
                    placeholder="blurred"
                    width={300}
                    alt="PhonePe"
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 'LISTER':
        return (
          <div className="relative flex  min-h-screen flex-col bg-lister-purple-700 p-5 text-center text-white">
            <div className="mb-14 flex  items-center justify-center justify-between md:mb-5">
              <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../../../images/lister/Event-Logo.png"
                placeholder="blurred"
                width={300}
                className="w-[100px] md:w-[160px]"
                alt="Lister"
              />

              <div className="mr-2 mt-2 self-start md:mr-4 md:mt-4 lg:flex">
                <a
                  href="/?utm_source=lister&utm_medium=post_application&utm_campaign=lister-beyond-your-break-program"
                  target="_self"
                >
                  <FontAwesomeIcon
                    className=" cursor-pointer  opacity-50 hover:opacity-25"
                    size="2x"
                    icon={['fas', 'times']}
                  ></FontAwesomeIcon>
                </a>
              </div>
            </div>
            <div className="flex flex-1 justify-center">
              <div className="lg:max-w-[400px]">
                <div className="reveal mb-8">
                  <h1
                    className={`reveal__content ${styles.title} font-nunito-sans text-3xl not-italic text-lister-purple-100 md:text-5xl`}
                  >
                    Thank You for your application!{' '}
                  </h1>
                </div>

                <div className="reveal  mb-10">
                  <p className="reveal__content text-xl font-normal leading-relaxed text-lister-purple-400">
                    We will communicate the next steps about your application
                    over email.
                  </p>{' '}
                </div>

                <div className="-bottom-0 left-1/2 mb-10 md:absolute md:mb-0 md:-translate-x-1/2">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/lister/Hero.png"
                    placeholder="blurred"
                    width={800}
                    className="w-[160px] md:w-[360px]"
                    alt="Hero"
                  />
                </div>
              </div>
            </div>
            <div className="mb-14 flex flex-col-reverse items-center  justify-center md:mb-5 md:flex-row lg:justify-between">
              <div className="w-[100px] md:w-auto">
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="lazy"
                  src="../../../images/lister/Crio-Logo.png"
                  placeholder="blurred"
                  width={200}
                  alt="Lister"
                />
              </div>
              <div className="my-4 h-[1px] w-3/4 bg-lister-purple-600 md:hidden"></div>
              <div className="w-[240px] justify-center md:w-auto lg:flex">
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="lazy"
                  src="../../../images/lister/Lister-Logo.png"
                  placeholder="blurred"
                  width={300}
                  alt="Lister"
                />
              </div>
            </div>
          </div>
        );

      case 'GEEK_TRUST':
        return (
          <div className="relative min-h-screen  bg-geek-trust-purple-200 p-5 text-center text-white">
            <div className="mb-14 flex  items-center justify-center md:mb-5 lg:justify-between">
              <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../../../images/geektrust/Event-Logo.png"
                placeholder="blurred"
                width={80}
                alt="Geek Trust"
              />
              <div className="absolute top-8 right-5 z-10 p-3 transition-all lg:left-0 lg:right-0 ">
                <a
                  href="/?utm_source=geektrust&utm_medium=post_application&utm_campaign=geektrust-tech-scholars-program"
                  target="_self"
                >
                  <FontAwesomeIcon
                    className=" cursor-pointer  opacity-50 hover:opacity-25"
                    size="2x"
                    icon={['fas', 'times']}
                  ></FontAwesomeIcon>
                </a>
              </div>
              <div className="hidden justify-center lg:flex">
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="lazy"
                  src="../../../images/geektrust/Crio-Logo.png"
                  placeholder="blurred"
                  width={150}
                  alt="Geek Trust"
                />
              </div>
            </div>
            <div className="absolute bottom-6 right-6 hidden text-right md:block">
              <p className="mt-4 mb-2 text-lg text-geek-trust-neutral-300">
                Powered by
              </p>
              <div className="w-[150px]">
                <StaticImage
                  imgStyle={{ objectFit: 'contain' }}
                  loading="lazy"
                  src="../../../images/geektrust/GeekTrust-Logo.png"
                  placeholder="blurred"
                  width={300}
                  alt="Geek Trust"
                />
              </div>
              {/* <StaticImage
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
                src="../../../images/geektrust/Geektrust-Logo.png"
                placeholder="blurred"
                width={150}
                alt="Geek Trust"
              /> */}
            </div>
            <div className="flex justify-center">
              <div className="lg:max-w-[400px]">
                <div className="reveal mb-8 md:mt-12">
                  <h1
                    className={`reveal__content ${styles.title} font-dosis font-normal not-italic text-geek-trust-neutral-100`}
                  >
                    Thank You for your application!{' '}
                  </h1>
                </div>

                <div className="reveal  mb-10">
                  <p className="reveal__content font-lato font-normal leading-relaxed  text-geek-trust-neutral-300">
                    We will communicate the next steps about your application
                    over email.
                  </p>{' '}
                </div>

                <div className="-bottom-0 left-1/2 mb-10 md:absolute md:mb-0 md:-translate-x-1/2">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/geektrust/Hero.png"
                    placeholder="blurred"
                    width={800}
                    className="w-[200px] md:w-[500px]"
                    alt="Hero"
                  />
                </div>

                {/* <div className="mb-10">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/geektrust/Hero.png"
                    placeholder="blurred"
                    width={800}
                    alt="Hero"
                  /> */}
                {/* </div> */}
                <div className="flex flex-col  items-center lg:hidden ">
                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/geektrust/Crio-Logo.png"
                    placeholder="blurred"
                    width={100}
                    alt="Geek Trust"
                  />
                  <p className=" my-2 text-xs text-geek-trust-neutral-300">
                    Powered by
                  </p>

                  <StaticImage
                    imgStyle={{ objectFit: 'contain' }}
                    loading="lazy"
                    src="../../../images/geektrust/GeekTrust-Logo.png"
                    placeholder="blurred"
                    width={100}
                    alt="Geek Trust"
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return getCompanyPage(props.secondaryType);
}
