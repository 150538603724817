import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import navigate from gatsby
import * as styles from './phonepe.module.scss';
import Grid from '../../../../images/v5/Launch/GridPattern.svg';
import MobileGrid from '../../../../images/v5/Launch/Mobile-Grid.svg';
import { Link } from 'gatsby';
import CrioButton from '@components/v5/CrioButton/index';
import { twMerge } from 'tailwind-merge';

export default function LaunchPostApplicationPage(props) {
  return (
    <div className="relative h-full  min-h-screen overflow-hidden bg-[#056B72] p-5 text-center text-white">
      <div className="absolute top-0 right-0  z-0 w-screen  opacity-25 ">
        <Grid height="100%" width="100%" className="hidden sm:block"></Grid>
        <MobileGrid
          height="100%"
          width="100%"
          className="sm:hidden "
        ></MobileGrid>
      </div>

      <div className="mb-8 flex  items-center justify-center sm:mb-5 lg:justify-between ">
        <Link to={'/automation-edge'}>
          <StaticImage
            loading="lazy"
            src="../../../../images/crio-reboot/Logo.webp"
            placeholder="none"
            width={150}
            alt="Crio logo"
            className="hidden lg:block"
          />
        </Link>
        {/* <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../../../images/phonepe/Logo.png"
          placeholder="blurred"
          width={80}
          alt="PhonePe"
        /> */}
        <div className=" z-10  p-3 transition-all sm:relative  ">
          <a
            href="/?utm_source=automation-edge&utm_medium=post_application&utm_campaign=automation-edge"
            target="_self"
          >
            <FontAwesomeIcon
              className=" cursor-pointer text-launch-neutral-100  hover:opacity-75"
              size="2x"
              icon={['fas', 'times']}
            ></FontAwesomeIcon>
          </a>
        </div>
      </div>
      <div className="flex justify-center">
        <div className=" lg:max-w-[600px]">
          <div className="reveal mb-8">
            <h1
              className={`reveal__content leading-tight ${styles.title} text-4xl `}
            >
              Thank You For <br className="hidden sm:block"></br>Your
              Application!{' '}
            </h1>
          </div>

          <div className="reveal  mb-10 flex flex-col items-center gap-4">
            <p className="reveal__content px-16 leading-relaxed sm:px-0">
              We will communicate the next steps to you shortly.
              <br className="hidden sm:block"></br> Meanwhile join our WhatsApp
              group to get updates about the events.
            </p>{' '}
            <CrioButton
              variant="crio-reboot"
              onClick={() => {
                window.open(
                  'https://chat.whatsapp.com/IzB635ca29h23fJOdY4zJC',
                  '_blank',
                );
              }}
              className={twMerge(`h-[64px] px-6`)}
            >
              <div className="pr-2 text-left">
                <h6 className="font-extrabold">Whatsapp Group</h6>
                {/* <p className="font-rubik text-xs font-light normal-case">
                First week of learning is free
              </p> */}
              </div>
            </CrioButton>
          </div>

          <div className="mb-10 flex justify-center">
            <div className="max-w-[400px] md:max-w-[500px]">
              <StaticImage
                loading="eager"
                src="../../../../images/crio-reboot/QA-Hero.webp"
                placeholder="none"
                alt="Hero Image"
                width={1000}
                imgStyle={{ objectFit: 'contain' }}
                className="w-full"
              />
            </div>
          </div>
          <div className="lg:hidden ">
            <Link to={'/automation-edge'}>
              <StaticImage
                loading="lazy"
                src="../../../../images/crio-reboot/Logo.webp"
                placeholder="none"
                width={120}
                alt="Crio logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
