import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';

export default function GreenBanner({ quote, postContent, gradient }) {
  return (
    <div className="relative z-[999] w-full sm:max-w-[950px]">
      <div
        className={`w-full ${
          gradient
            ? 'gradient-card-light border-gradient border-gradient-radius30'
            : 'bg-v5-green-100'
        } p-6 text-center  sm:p-8`}
        style={{ borderRadius: '30px' }}
      >
        <div className="">
          <h3 className="mb-5 font-manrope text-xl font-extrabold leading-tight text-black sm:text-[28px] ">
            <RawHTML>{quote}</RawHTML>
          </h3>
          <div className="flex w-full flex-wrap justify-center gap-x-2 gap-y-2 px-4">
            {postContent}
          </div>
        </div>
      </div>
    </div>
  );
}
