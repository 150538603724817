import Button from '@components/Button/index';
// import AwesomeConfetti from '@components/Confetti/index';
import { useQuery } from '@components/extra/useQuery';
import useResizer from '@components/extra/useResizer';
import RegisteredFAQ from '@components/RegisteredFAQ/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Link, navigate } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { useContext, useEffect } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import toast from 'react-hot-toast';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import faq from '../../images/faq-bg.png';
import testimonial from '../../images/Testimonial.png';
import * as styles from './Registered.module.scss';
import { doLoginToBackend } from '@src/api/index';
import { useAuth } from '@src/firebase/useAuth';
import CompanyEventPostApplicationPage from '@components/CompanyEventPages/CompanyEventPostApplicationPage/index';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';
import LaunchPostApplicationPage from '@components/v5/launch/LaunchPostApplicationPage/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';

export default function Registered(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const isMobile = useResizer();
  const auth = useAuth();
  const id = useQuery('id');
  const entry =
    state.registrationDetails &&
    state.registrationDetails[
      Object.keys(state.registrationDetails).find(
        (ele) => state.registrationDetails[ele].id == id,
      )
    ];

  useEffect(() => {
    if (!Boolean(entry)) {
      navigate('/');
    }
  }, []);

  if (!Boolean(entry)) {
    return <></>;
  }
  // const strapi_data = db['strapi_metadata']['programs'].find(
  //   (ele) => ele.PrimaryProgramID == entry.type
  // );
  const strapi_data = entry.program;

  const companiesData = props.data.allStrapiCompanies.nodes.reduce(
    (result, obj) => {
      result.push({
        id: obj.strapiId,
        name: obj.Name,
        url: obj.Logo.localFile,
      });
      return result;
    },
    [],
  );

  const allYoutubeThumbnail = props.data.allYoutubeThumbnail.nodes;

  const testimonialQuotesData =
    props.data.allStrapiTestimonialWithQuotes.nodes.reduce((result, obj) => {
      result.push({
        name: obj.graduate.Name,
        linkedin: obj.LinkedInLink,
        companyLogo: companiesData.find((val) => val.id == obj.graduate.company)
          ?.url,
        iFrame: obj.YoutubeVideoLink,
        quote: obj.Quote,
        thumbnail: allYoutubeThumbnail.find(
          (ele) => ele.name == String(obj.strapiId),
        ).childImageSharp,
      });
      return result;
    }, []);

  const getHeader = (type) => {
    switch (type) {
      case 'BRAND_PARTNER':
      case 'LAUNCH':
        return <></>;
      case 'FELLOWSHIP':
      case 'MASTERS_PROGRAM':
      case 'QA_AUTOMATION_TESTING':
        return <Navigation hideCTA></Navigation>;
      default:
        return <Navigation hideCTA></Navigation>;
    }
  };

  const handlePostFirebaseLogin = async (idToken, cb) => {
    let response = await doLoginToBackend(idToken);
    if (response) {
      cb();
    } else {
      toast.error(
        'Authentication failed due to poor network connection. Please refresh your browser and try again.',
        { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
      );
    }
  };

  async function handleLogin(e, url) {
    e.preventDefault();
    if (auth?.user === null) {
      return;
    }
    if (auth?.user) {
      const idToken = await auth.getIdToken();
      handlePostFirebaseLogin(idToken, () => {
        window.open(url, '_blank');
      });
      return;
    }
    auth.signin().then(async (res) => {
      const idToken = res.idToken;
      handlePostFirebaseLogin(idToken, () => {
        window.open(url, '_blank');
      });
      return;
    });
  }

  function getApplicationSubmissionScreen(entry) {
    let temp = '';
    if (typeof window != 'undefined') {
      temp = window.location.hostname;
      temp = temp.replace('www.', '');
    }

    switch (entry.type) {
      case 'BACKEND_DEVELOPER_EXPERIENCE':
      case 'FULL_STACK':
      case 'FULL_STACK_SP_EDITION':
      case 'FELLOWSHIP':
      case 'MASTERS_PROGRAM':
      case 'QA_AUTOMATION_TESTING':
        return (
          <div
            className=""
            style={{
              background: '#f3f1ee',
              backgroundImage: `url(${testimonial})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <RegisteredCustomView
              entry={entry}
              strapi_data={strapi_data}
              testimonialQuotesData={testimonialQuotesData}
              handleLogin={handleLogin}
            ></RegisteredCustomView>
          </div>
        );
      case 'FREE_TRIAL':
      case 'FREE_TRIAL_OFFLINE':
        return (
          <div
            className=""
            style={{
              background: '#f3f1ee',
              backgroundImage: `url(${testimonial})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <RegisteredCustomView
              entry={entry}
              strapi_data={strapi_data}
              testimonialQuotesData={testimonialQuotesData}
              handleLogin={handleLogin}
            ></RegisteredCustomView>
          </div>
        );

      case 'BRAND_PARTNER':
        return (
          <CompanyEventPostApplicationPage
            {...entry}
          ></CompanyEventPostApplicationPage>
        );
      case 'LAUNCH':
        return (
          <LaunchPostApplicationPage {...entry}></LaunchPostApplicationPage>
        );
      default:
        return (
          <section
            className="pt-36"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              zIndex: '1',
              background: 'rgb(231, 243, 254)',
            }}
          >
            <RegisteredDefaultView
              entry={entry}
              handleLogin={handleLogin}
            ></RegisteredDefaultView>
          </section>
        );
    }
  }
  return (
    <>
      <GatsbySeo
        noindex={true}
        title="Registration Successful"
        description="Build internship-grade projects in a real developer environment and accelerate your full stack or backend developer career with a job-ready project portfolio. Enroll and start for free."
      />

      <div className="fixed-header">{getHeader(entry.type)}</div>

      {/* {isMobile && (
        <AwesomeConfetti
          particleCount={5}
          angle={90}
          spread={55}
          startVelocity={50}
          colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
        />
      )}

      {!isMobile && (
        <>
          <AwesomeConfetti
            particleCount={40}
            angle={60}
            spread={55}
            startVelocity={70}
            origin={{ x: 0 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
          <AwesomeConfetti
            particleCount={40}
            startVelocity={70}
            angle={120}
            spread={55}
            origin={{ x: 1 }}
            colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
          />
        </>
      )} */}

      {getApplicationSubmissionScreen(entry)}
      {/* {entry.type != 'BRAND_PARTNER' && <Footer></Footer>} */}
      {!['BRAND_PARTNER', 'LAUNCH'].includes(entry.type) && <FooterV2 />}

      {/* <FooterImage></FooterImage> */}
    </>
  );
}

function RegisteredDefaultView(props) {
  const getNextStepSection = (type) => {
    switch (type) {
      case 'CWOD':
        return (
          <>
            <p className="pl-3 pr-3 md:pl-5 md:pr-5">
              Thank you for your application.<br></br>
              <br></br>
              We will review your application and get back to you in a few days
              with the next steps.
            </p>
            <br></br>
            <p className="mb-4 mt-4 font-bold">
              Explore Crio’s Signature Programs
            </p>
            <div className="flex justify-center">
              <Link to="/#programs">
                <Button type="primary">Explore Now</Button>
              </Link>
            </div>
          </>
        );
        break;

      case 'RECRUIT_PAGE':
        return (
          <>
            <p className="pl-3 pr-3 lg:pl-5 lg:pr-5">
              Thank you for showing interest in hiring from Crio. We will get
              back to you soon with the next steps.
            </p>
            <div className="mt-6 flex justify-center">
              <Link to="/recruit">
                <Button type="primary">Back to Home</Button>
              </Link>
            </div>
          </>
        );
        break;

      default:
        return (
          <p className="pl-3 pr-3 lg:pl-5 lg:pr-5">
            We're excited and can't wait to start the software development
            journey with you. <br></br>
            <br></br>
            Login to platform (use the same email provided in your application)
            and try your hands with free Micro-Experiences and Crio Bytes.
          </p>
        );
        break;
    }
  };

  const getLoginButton = (type) => {
    switch (type) {
      case 'RECRUIT_PAGE':
        return <></>;
        break;

      default:
        return (
          <div className="flex justify-center">
            <Button
              type="primary"
              onClick={(e) => {
                GTM.track(gtmEvents.TYPEFORM_LOGIN_CLICKED, {
                  type: props.entry.type,
                  location: 'TYPEFORM_TOP',
                });
                props.handleLogin(e, '/learn/home');
              }}
            >
              Login Now
            </Button>
          </div>
        );
        break;
    }
  };

  return (
    <span>
      <div className="section">
        <div className="container  mb-4 flex flex-col items-center text-center">
          <svg
            id="successAnimation"
            className="animated"
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
          >
            <path
              id="successAnimationResult"
              fill="#D8D8D8"
              d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
            />
            <circle
              id="successAnimationCircle"
              cx="35"
              cy="35"
              r="24"
              stroke="#979797"
              strokeWidth="2"
              strokeLinecap="round"
              fill="transparent"
            />
            <polyline
              id="successAnimationCheck"
              stroke="#979797"
              strokeWidth="2"
              points="23 34 34 43 47 27"
              fill="transparent"
            />
          </svg>
          <h3>Application Submitted!</h3>
          {getNextStepSection(props.entry.type)}
        </div>
      </div>{' '}
      <p className="mb-4 mt-4 text-center">
        {props.entry.type !== 'RECRUIT_PAGE' && (
          <strong className="mb-4">Let’s Start your Crio Journey</strong>
        )}
        {getLoginButton(props.entry.type)}
      </p>
      <section className="pb-24 pt-24 text-center ">
        <div className="container">
          <div>
            <h2 className="container    mb-4">Developers love Crio Platform</h2>
          </div>
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center">
              <div className="col-6  col-lg-3 mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/jumbotail.png"
                  placeholder="blurred"
                  alt="Jumbotail"
                  className={styles.userImage}
                />
                {/* <Image filename="testimonials/jumbotail.png"></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “It was a wonderful journey with several practical learning
                  experiences. Crio made me realize that the best way to learn
                  is by doing. I experienced the power of systematic coding,
                  test-driven development, and scientific debugging. The
                  constant support and push from mentors were marvelous. I honed
                  my skills in software development, problem-solving, and
                  debugging.”
                </div>
                <div className="subtitle1 mb-1  flex items-center">
                  <div className="mr-1 ">
                    - Anubhav Bhargava, Software Engineer{' '}
                  </div>
                  <a
                    href={'https://www.linkedin.com/in/anubhavbhargava/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i> Jumbotail</i>
                </div>
              </div>
            </div>
          </div>{' '}
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center">
              <div className="col-6  col-lg-3  order-md-last mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/ninjacart.png"
                  placeholder="blurred"
                  alt="Ninjakart"
                  className={styles.userImage}
                />
                {/* <Image
                  filename="testimonials/ninjacart.png"
                  className={styles.userImage}
                ></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “Enrolling for Crio’s program proved to be a master stroke for
                  me. When I joined this program, I was low on confidence about
                  my skills, lost touch with programming - it was all low. This
                  program helped me find back my love for programming. I grew
                  more confident in solving problems. My development skills
                  improved big time. The mentors responded to my doubts promptly
                  and helped us find the right path by ourselves. The program
                  was incremental. It started from the basics and after every
                  completed module I saw myself making a big stride forward.
                  Each module was demanding in itself and required a lot of
                  googling and understanding of concepts to implement them. The
                  team at Crio is excellent. They were so patient and
                  professional. The four months I spent for this program are
                  valuable. I learned a lot by implementing a lot of things in a
                  work-like environment. I am elated that I was able to crack
                  the very first company that I appeared for - Ninjacart. ”
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">
                    - Venkata Amaresh, Software Engineer
                  </div>
                  <a
                    href={'https://www.linkedin.com/in/vamareshi/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i> Ninjacart</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-24 pb-24 text-center"
        // style={{
        //   backgroundImage: `url(${upskill})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <div>
          <h2 className="container    mb-4">
            Our learning platform emulates a work-like environment in which
            developers build real projects
          </h2>
        </div>
        <br></br>
        <div className="container   text-center ">
          {' '}
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center">
              <div className="col-6  col-lg-3 mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/cred.png"
                  placeholder="blurred"
                  alt="CRED"
                  className={styles.userImage}
                />
                {/* <Image
                  filename="testimonials/cred.png"
                  className={styles.userImage}
                ></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “It was a wonderful journey with a lot of practical learning.
                  With no experience in many of the tech stacks like Spring
                  Boot, MongoDB, etc I still managed to build a scalable
                  distributed backend system that is running live in Google
                  Cloud. All this was possible because of the wonderful team at
                  Crio who made us realise that "learning is doing". The
                  experience that Crio provides cannot be provided to the video
                  courses out there. Crio is completely different because you
                  work just like a developer works in a company and build real
                  stuff that you can showcase. Also, the capstone project at the
                  end was a thrilling experience where we built a working
                  feature in a limited time on our own. All thanks to the team
                  at Crio for their wonderful support and mentorship.”
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">- Piyush Pant, Backend Engineer </div>
                  <a
                    href={'https://www.linkedin.com/in/piyushpant17/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i> CRED</i>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center ">
              <div className="col-6  col-lg-3   order-md-last mb-3 md:mb-0">
                {/* <Image
                  filename="testimonials/vogo.png"
                  className={styles.userImage}
                ></Image> */}
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/vogo.png"
                  placeholder="blurred"
                  alt="Vogo"
                  className={styles.userImage}
                />
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “Crio Micro-Experiences are the best thing that has happened
                  to me. When I applied for this program I thought it might be
                  similar to the other programs in the market - watching
                  assigned videos and implementing things. But I was wrong. The
                  learning I got from Crio is incomparable. The modules were
                  designed in such a way that without learning about the
                  concepts enough, you won't be able to implement it. There's no
                  shortcut. Proper time must be allotted to reading
                  documentation, tutorials, blogs and more to cross the modules.
                  The program made sure you get out of your comfort zone and
                  thrive. Completing Crio experience helped me in appearing for
                  interviews confidently and cracking one. I got placed in a
                  fast-growing startup - VOGO automotive.”
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">
                    - Roshan Dawande, Software Engineer
                  </div>
                  <a
                    href={'https://www.linkedin.com/in/rdroshan/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i>Vogo</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-24 pb-24 text-center"
        // style={{
        //   backgroundImage: `url(${platform})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <div>
          <h2 className="container   mb-4">
            Crio mentors come with years of experience in top product companies
          </h2>
        </div>
        <br></br>
        <div className="container   text-center ">
          {' '}
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center">
              <div className="col-6  col-lg-3  mb-3 md:mb-0">
                {/* <Image
                  filename="testimonials/wd.png"
                  className={styles.userImage}
                ></Image> */}
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/wd.png"
                  placeholder="blurred"
                  alt="WD"
                  className={styles.userImage}
                />
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “My Crio journey was awesome and it feels great that I was a
                  part of it! Through the Micro-Experiences I have learnt a
                  bunch of industry relevant skills. There were several modules
                  where I was stuck for long, but the team at Crio always kept
                  me motivated and guided me in the right direction. My major
                  takeaways from the program are the importance of learning how
                  best to learn and pushing myself harder.”
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">- Aarshiya Khandelwal, Engineer</div>
                  <a
                    href={'https://www.linkedin.com/in/aarshiya-khandelwal/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i>Western Digital</i>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center">
              <div className="col-6  col-lg-3 order-md-last mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/vit.png"
                  placeholder="blurred"
                  alt="VIT"
                  className={styles.userImage}
                />
                {/* <Image
                  filename="testimonials/vit.png"
                  className={styles.userImage}
                ></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “I have completed multiple bootcamps, tutorials, video
                  lectures and tried many types of learning methodologies that
                  are claimed to be the best, but Crio begs to differ. An
                  e-learning startup with a completely novel approach to hand-on
                  learning was the best learning experience I had until now. I
                  got to work on 4 different projects and a mini project which
                  covered tracks like REST API, networking and network security,
                  OS concepts, unit testing,Java libraries and frameworks
                  (JUnit, Spring, Jackson, Mockito, among others), python
                  libraries and frameworks, build tools (Gradle, Maven) and
                  applied data structures. They also taught some uncommon skills
                  like regression testing and debugging which are of importance
                  while working on a big project but are often neglected by
                  tutorials. Apart from that, the projects are completely
                  scale-able applications which are modular to every need, as
                  it's supposed to be in a product company. They deliver on
                  their promise of providing "Work-like Experience" through
                  their methodology.“
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">- Apoorv Goyal, Student Developer</div>
                  <a
                    href={'https://www.linkedin.com/in/apoorv-goyal/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i> Vellore Institute of Technology</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-24 pb-24 text-center"
        // style={{
        //   backgroundImage: `url(${platform})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <div>
          <h2 className="container   mb-4">
            Our community is filled with like-minded developers, passionate
            about learning by doing{' '}
          </h2>
        </div>
        <br></br>
        <div className="container   text-center ">
          {' '}
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center ">
              <div className="col-6  col-lg-3 mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/tino.png"
                  placeholder="blurred"
                  alt="Tino"
                  className={styles.userImage}
                />
                {/* <Image
                  filename="testimonials/tino.png"
                  className={styles.userImage}
                ></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  I have seen many online YouTube videos and various courses on
                  programming, coding and software development in general. Most
                  of these involve only a few topics and give a certain level of
                  understanding around these topics. Also this understanding is
                  limited to what is taught by these courses unless students
                  themselves take effort to learn more about them. Real learning
                  happens only when we join a company or startup. <br></br>
                  <br></br>Crio’s program has proven to be an exception among
                  all these . You will feel the philosophy of Crio, "Learning by
                  Doing", imbibed rightfully into their extensive program. This
                  is really a once in a lifetime opportunity for those who want
                  to experience what software development is all about. This 10
                  weeks program taught me many things which seldom I could have
                  learned from all these courses. From working my way around
                  writing shell scripts to interacting with APIs in Java and
                  then applying algorithms to real life problems, it was a
                  joyful 10 week ride.
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">
                    - Pratik Naik, Software Development Intern
                  </div>
                  <a
                    href={'https://www.linkedin.com/in/pratik-naik-aa9262157/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i>TINO IQ</i>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.testimonialCard} mb-4 p-4`}>
            <div className="row flex justify-center ">
              <div className="col-6  col-lg-3  order-md-last mb-3 md:mb-0">
                <StaticImage
                  loading="lazy"
                  src="../../images/testimonials/bitmesra.png"
                  placeholder="blurred"
                  alt="BIT Mesra"
                  className={styles.userImage}
                />
                {/* <Image
                  className={styles.userImage}
                  filename="testimonials/bitmesra.png"
                ></Image> */}
              </div>
              <div className="col-lg-9 text-left">
                <div className="bodyText1 mb-2">
                  “The past 10 weeks were nothing short of a roller coaster ride
                  to me. From solving those tough real world problems to those
                  countless nights that I have spent brainstorming, everything
                  was extremely useful and will surely help me in my future.
                  <br></br>
                  <br></br> In terms of learning the program is well formed and
                  organised. The Micro-Experiences that are lined up have well
                  organised modules covering in-demand interview and industry
                  relevant skills. I would like to thank the Crio team for such
                  a great learning experience. I would highly recommend this
                  program to anyone who wants to learn the art of "learning by
                  doing" and wants to get an internship-like experience. Thanks
                  Crio.Do, and I'm looking forward to participating in future
                  programs as well.“
                </div>
                <div className="subtitle1 flex  items-center">
                  <div className="mr-1">- Ayush Shah, Student Developer</div>
                  <a
                    href={'https://www.linkedin.com/in/ayushshah1234/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'linkedin']}
                      style={{
                        color: '#52A5EF',
                        // marginRight: "1rem",
                        // marginTop: "-4px",
                        // fontSize: "30px",
                      }}
                      size="lg"
                    />{' '}
                  </a>
                </div>
                <div className="subtitle1 font-light">
                  <i>BIT Mesra</i>
                </div>
              </div>
            </div>
          </div>{' '}
          {props.entry.type == 'CWOD' ? (
            <div className=" flex  items-center justify-center ">
              <Link to="/#programs">
                <Button type="primary">Explore Now</Button>
              </Link>
            </div>
          ) : (
            getLoginButton(props.entry.type)
          )}
        </div>
      </section>
    </span>
  );
}

function RegisteredCustomView(props) {
  const isMobile = useResizer();
  const state = useContext(GlobalStateContext);

  return (
    <span>
      <div className="container">
        <section
          className={`pt-28 ${
            props?.entry?.type !== 'QA_AUTOMATION_TESTING' && 'pb-20'
          }`}
        >
          <div className="row pt-10">
            <div className="col-12 col-lg-12">
              <span className="text-center">
                <h1 className={`mb-4 text-crio-green-900 ${styles.customFont}`}>
                  You are almost there!
                </h1>
                <p className="mb-10 text-lg text-gray-600">
                  Thank you for applying.
                  <strong> Complete these 3 simple steps</strong> and get ready
                  for the <br className="hidden md:block"></br>
                  <strong>
                    trial batch starting on{' '}
                    {format(
                      new Date(props.strapi_data.ProgramDate),
                      'MMMM dd, yyyy',
                    )}{' '}
                    at{' '}
                    {new Date(
                      '1970-01-01T' + props.strapi_data.ProgramTime + 'Z',
                    ).toLocaleTimeString(
                      {},
                      {
                        timeZone: 'UTC',
                        hour12: true,
                        hour: 'numeric',
                        minute: 'numeric',
                      },
                    )}{' '}
                    {props.strapi_data.Timezone}
                  </strong>
                </p>

                <div className="container-fluid">
                  <div className={`row`}>
                    <div className="col-12 col-md-4">
                      <div className="">
                        <div className="mb-5 w-full rounded-lg bg-crio-green-100  px-3 py-3 text-crio-green-900 md:flex  md:flex-col md:px-5">
                          <div className={`row ${styles.customFont} `}>
                            <div className="col-3 flex items-center md:hidden">
                              <div className="md:mb-10">
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/PostApplication/Three.png"
                                  placeholder="blurred"
                                  alt="Step 3"
                                  style={{
                                    minWidth: '80px',
                                    width: '100%',
                                  }}
                                />
                                {/* <Image filename="PostApplication/Three.png"></Image> */}
                              </div>
                            </div>
                            <div className="col-9 col-md-12 text-left md:text-center">
                              <h5
                                className={` hidden font-semibold md:mb-5 md:block`}
                              >
                                Step 1
                              </h5>
                              <p className="mb-3  font-normal md:hidden">
                                STEP 1
                              </p>
                              <div className="mb-5 hidden justify-center  md:flex">
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/PostApplication/Three.png"
                                  placeholder="blurred"
                                  alt="Step 3"
                                  style={{ width: '150px' }}
                                />
                                {/* <Image
                                  filename="PostApplication/Three.png"
                                  style={{ width: '150px' }}
                                ></Image> */}
                              </div>
                              <h6 className="mb-5 font-semibold">
                                Ensure you get critical trial-related updates
                              </h6>
                              <a
                                href={props.strapi_data.WhatsappLink}
                                target="_blank"
                                onClick={() => {
                                  GTM.track(
                                    gtmEvents.POST_COMPLETION_WHATSAPP_INVITATION_CLICKED,
                                    {
                                      type: props.entry.type,
                                      location: props.entry.location,
                                    },
                                  );
                                }}
                                rel="noreferrer"
                              >
                                <div className="flex	cursor-pointer justify-center rounded-lg  bg-white px-3 py-3 text-sm font-bold text-crio-green-900 transition-all hover:bg-gray-300">
                                  Join the Crio Whatsapp Group
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="hidden text-left text-sm font-light leading-loose md:block">
                          Stay up to date with critical trial-related updates on
                          this Broadcast only whatsapp group [Only critical
                          information, no spam]
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="">
                        <div className="mb-5 w-full rounded-lg bg-crio-green-200 px-3   py-3 text-crio-green-900 md:flex  md:flex-col 	md:px-5">
                          <div className={`row ${styles.customFont} `}>
                            <div className="col-3 flex items-center md:hidden">
                              <div className="md:mb-10">
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/PostApplication/One.png"
                                  placeholder="blurred"
                                  alt="Step 1"
                                  style={{
                                    minWidth: '80px',
                                    width: '100%',
                                  }}
                                />
                                {/* <Image
                                  filename="PostApplication/One.png"
                                  style={{
                                    minWidth: '80px',
                                    width: '100%',
                                  }}
                                ></Image> */}
                              </div>
                            </div>
                            <div className="col-9 col-md-12 text-left md:text-center">
                              <h5 className="hidden font-semibold md:mb-5 md:block">
                                Step 2
                              </h5>
                              <p className="mb-3  font-normal md:hidden">
                                STEP 2
                              </p>

                              <div className="mb-5 hidden justify-center  md:flex">
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/PostApplication/One.png"
                                  placeholder="blurred"
                                  alt="Step 1"
                                  style={{ width: '150px' }}
                                />
                                {/* <Image
                                  filename="PostApplication/One.png"
                                  style={{ width: '150px' }}
                                ></Image> */}
                              </div>
                              <h6 className="mb-5 font-semibold">
                                Connect with Crio mentors and other developers
                              </h6>
                              <a
                                href={props.strapi_data.SlackInviteLink}
                                target="_blank"
                                onClick={() => {
                                  GTM.track(
                                    gtmEvents.POST_COMPLETION_JOIN_SLACK_CLICKED,
                                    {
                                      type: props.entry.type,
                                      location: props.entry.location,
                                    },
                                  );
                                }}
                                rel="noreferrer"
                              >
                                <div className="flex	cursor-pointer justify-center rounded-lg  bg-white px-3 py-3 text-sm font-bold text-crio-green-900 transition-all hover:bg-gray-300">
                                  Join the Crio Slack Community
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="hidden text-left text-sm font-light leading-loose md:block">
                          Join the community to ensure you get guidance from
                          Crio Mentors during the trial and say hi to developers
                          learning by doing with Crio. Follow the{' '}
                          <span className="font-bold">
                            {props.strapi_data.SlackChannel}
                          </span>{' '}
                          channel.
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="">
                        <div className="mb-5 w-full rounded-lg bg-crio-green-500 px-3 py-3 text-crio-green-900 md:flex  md:flex-col 		md:px-5">
                          <div className={`${styles.customFont}  row`}>
                            <div className="col-3 flex items-center md:hidden">
                              <div className="md:mb-10">
                                {props?.entry?.type !== 'FELLOWSHIP' &&
                                props?.entry?.type !==
                                  'QA_AUTOMATION_TESTING' ? (
                                  <StaticImage
                                    loading="lazy"
                                    src="../../images/PostApplication/Two.png"
                                    placeholder="blurred"
                                    alt="Step 2"
                                    style={{
                                      minWidth: '80px',
                                      width: '100%',
                                    }}
                                  />
                                ) : (
                                  // <Image
                                  //   filename="PostApplication/Two.png"
                                  //   style={{
                                  //     minWidth: '80px',
                                  //     width: '100%',
                                  //   }}
                                  // ></Image>
                                  <StaticImage
                                    loading="lazy"
                                    src="../../images/PostApplication/Four.png"
                                    placeholder="blurred"
                                    alt="Step 4"
                                    style={{
                                      minWidth: '80px',
                                      width: '100%',
                                    }}
                                  />
                                  // <Image
                                  //   filename="PostApplication/Four.png"
                                  //   style={{
                                  //     minWidth: '80px',
                                  //     width: '100%',
                                  //   }}
                                  // ></Image>
                                )}
                              </div>
                            </div>
                            <div className="col-9 col-md-12 text-left md:text-center">
                              <h5
                                className={`font-semibold ${
                                  props?.entry?.type !== 'FELLOWSHIP' &&
                                  props?.entry?.type !== 'QA_AUTOMATION_TESTING'
                                    ? 'mb-0'
                                    : 'mb-5'
                                } hidden md:block`}
                              >
                                Step 3
                              </h5>
                              <p className="mb-3  font-normal md:hidden">
                                STEP 3
                              </p>

                              {
                                <>
                                  <div className="mb-[0.2rem] hidden justify-center  md:flex">
                                    <StaticImage
                                      loading="lazy"
                                      src="../../images/PostApplication/Four.png"
                                      placeholder="blurred"
                                      alt="Step 4"
                                      style={{ width: '150px' }}
                                    />
                                    {/* <Image
                                      filename="PostApplication/Four.png"
                                      style={{ width: '150px' }}
                                    ></Image> */}
                                  </div>
                                  <h6 className="mb-5 font-semibold">
                                    Find out how Crio's programs can transform
                                    your career
                                  </h6>
                                  <a
                                    href={
                                      'https://rebrand.ly/Crio-Survey' +
                                      (Boolean(state.utm)
                                        ? '?' + state.utm
                                        : '')
                                    }
                                    target="_blank"
                                    onClick={(e) => {
                                      GTM.track(
                                        gtmEvents.POST_COMPLETION_GET_MIN_SALARY_CLICKED,
                                        {
                                          type: props.entry.type,
                                          location: props.entry.location,
                                        },
                                      );
                                    }}
                                    rel="noreferrer"
                                  >
                                    <div className="flex	cursor-pointer justify-center rounded-lg  bg-white px-3 py-3  text-center text-sm font-bold  text-crio-green-900 transition-all hover:bg-gray-300">
                                      Get your minimum Crio assured salary
                                    </div>
                                  </a>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        <p className="hidden text-left text-sm font-light leading-loose md:block">
                          {props?.entry?.type !== 'FELLOWSHIP' &&
                          props?.entry?.type !== 'QA_AUTOMATION_TESTING' ? (
                            <>
                              Help us understand your profile in 2 mins, and we
                              will be able to share{' '}
                              <span className="font-bold">
                                a personalised career plan and starting salary
                                ranges
                              </span>{' '}
                              you can achieve through our program.
                            </>
                          ) : (
                            <>
                              Help us understand your profile in 2 mins, and we
                              will be able to share{' '}
                              <span className="font-bold">
                                a personalised career plan and the minimum
                                assured salary
                              </span>{' '}
                              you can achieve through our program.
                            </>
                          )}
                        </p>
                      </div>
                      {/* <div className="text-left md:hidden">
                        <p>
                          Try this activity on your laptop. You can find the
                          link to this activity in your e-mail.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </section>
      </div>
      {props?.entry?.type !== 'QA_AUTOMATION_TESTING' && (
        <section className="bg-white pt-16 pb-16">
          <div className="container">
            <div className="row flex items-center">
              <div className="col-12 col-lg-6 mb-5 md:mb-0">
                <span className="">
                  <h3
                    className={`mb-4 text-crio-green-600 ${styles.customFont}`}
                  >
                    Your Free Trial Preview
                  </h3>
                  <p className="mb-6 pr-10 text-gray-600">
                    Watch this video from Ajay, Crio’s founding member and
                    ex-Googler, on what’s in store for you in our Free Trial.
                  </p>
                </span>
              </div>{' '}
              <div className="col-12 col-lg-6 ">
                {' '}
                <div
                  className=" mb-10 md:mr-9 md:mb-0"
                  style={{
                    position: 'relative',
                    paddingBottom: '56.25%' /* 16:9 */,
                    paddingTop: 25,
                    height: 0,
                    transform: 'translateZ(0px)',
                    display: 'block',
                    overflow: 'hidden',
                    // borderRadius: "7px",
                  }}
                >
                  <iframe
                    loading="lazy"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    src={`https://www.youtube.com/embed/lD1Jxqq1Z44?rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do`}
                    frameBorder="0"
                    allowFullScreen="allowFullScreen"
                  />
                </div>{' '}
              </div>
            </div>
          </div>
        </section>
      )}
      <section
        className="pt-16 pb-16"
        style={{
          backgroundImage: `url(${faq})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="container text-center">
          <h2 className={`mb-10  text-crio-green-900 ${styles.customFont}`}>
            Have questions on your mind?
          </h2>

          <div className="flex  flex-col items-center justify-center md:flex-row">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeUlfZMSWHwhRQ249oJHenzwxLW196oAfNnFeWDDHlz6vPyYw/viewform"
              className="md:mr-7 "
              target="_blank"
              onClick={() => {
                GTM.track(gtmEvents.POST_COMPLETION_TALK_TO_COUNSELLOR, {
                  type: props.entry.type,
                  location: props.entry.location,
                });
              }}
              rel="noreferrer"
            >
              <div className="mb-5 flex w-60 cursor-pointer justify-center	whitespace-nowrap rounded-md bg-crio-green-600 px-5 py-3 font-bold text-white transition-all  hover:bg-crio-green-800 md:mb-0 md:px-10">
                Talk to a Crio counsellor
              </div>
            </a>
            {props.entry.program.countryName == 'India__IN' && (
              <a
                href="#faqs"
                onClick={() => {
                  GTM.track(gtmEvents.POST_COMPLETION_VIEW_FAQS, {
                    type: props.entry.type,
                    location: props.entry.location,
                  });
                }}
              >
                <div className=" flex w-60 cursor-pointer	justify-center whitespace-nowrap rounded-md bg-crio-green-600 px-5 py-3 font-bold text-white  transition-all hover:bg-crio-green-800 md:px-10">
                  View FAQs
                </div>
              </a>
            )}
          </div>
        </div>
      </section>
      {/*  */}
      <div className="bg-v5-neutral-100">
        <VideoTestimonialSection disableGreenBanner></VideoTestimonialSection>
      </div>
      <span id="faqs"></span>
      <section
        className="pt-16 pb-16"
        style={{
          background: '#f3f1ee',
          backgroundImage: `url(${testimonial})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {' '}
        <div className="container">
          <h5 className="mb-6 font-light">FAQs</h5>
          <RegisteredFAQ type={props?.entry?.type}></RegisteredFAQ>
        </div>
      </section>
    </span>
  );
}

export const pageQuery = graphql`
  query {
    ...strapiDataFragment

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }
  }
`;
