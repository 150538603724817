import React from 'react';
import * as styles from './RegisteredFAQ.module.scss';

export default function RegisteredFAQ(props) {
  const getScholarship = () => {
    return "Scholarships are decided based on your profile and your performance in the program's free trial.";
  };

  return (
    <div className="row container ">
      <div className="col-12 col-lg-6 mb-10 p-0">
        <h5>Trial Session</h5>
        <div className="col-12 flex w-full flex-col p-0 md:w-11/12">
          <div className="">
            <div className={`${styles.accordion} custom mb-4`}>
              <ul>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What is the free trial ?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      In the free trial, you will be having a trial webinar
                      where you will be introduced to the Crio’s #LearnByDoing
                      platform. You will also be given access to our learning
                      platform for you to start your #LearnByDoing journey. All
                      further instructions will be shared during the webinar
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How do I start my free trial
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      After you book a free trial with us, our counsellor will
                      be getting in touch with you within 24 hours and the
                      details regarding the webinar will be shared with you on
                      your registered email id.
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    Is it mandatory to attend the free trial?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      We highly recommend that you attend the trial webinar to
                      get answers to all doubts that you might have about the
                      Crio’s signature learning programs and the #LearnByDoing
                      platform.
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What skills will I learn in my free trial?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      You will get an exposure to various aspects of a fullstack
                      application - HTML, CSS, HTTP, REST and backend server.
                    </div>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-10 p-0">
        <h5>Selection and Scholarships</h5>
        <div className="col-12 flex w-full flex-col p-0 md:w-11/12">
          <div className="">
            <div className={`${styles.accordion} custom mb-4`}>
              <ul>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What is the maximum scholarship I can get after completing
                    the free trial curriculum?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>{getScholarship(props)}</li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What is the criteria to get max. Scholarship?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>Complete the free trial curriculum on schedule.</li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How will I know what % scholarship I have earned?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          After you complete the free trial curriculum, our team
                          will send you an email with all details (including the
                          scholarship you have earned).
                        </li>
                        <li>
                          If you have any further questions, our team will reach
                          out to you to address them to your satisfaction.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How long is the scholarship valid?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          We will communicate these details in the email you
                          receive after you complete the free trial curriculum.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What can I use the scholarship for?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          You can use your scholarship to enroll into any of
                          Crio’s signature learning programs (Backend/Full
                          Stack)
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-10 p-0">
        <h5>Crio Signature Learning Tracks</h5>
        <div className="col-12 flex w-full flex-col p-0 md:w-11/12">
          <div className="">
            <div className={`${styles.accordion} custom mb-4`}>
              <ul>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What learning programs does Crio offer?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          We currently offer two project-based learning programs
                          (we are working hard to add more):
                          <ol type="a" className="pl-2">
                            <li>Backend Developer Program</li>
                            <li>Full Stack Developer Program</li>
                          </ol>
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How do I enroll into Crio’s signature learning programs?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          The best way is to start with the free trial and get a
                          flavour of Crio’s content and learning methodology.
                        </li>
                        <li>
                          After you complete the trial, we will send you details
                          to apply to our signature learning programs with the
                          program fee for each and scholarship details.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    Is there a way for me to earn scholarships without
                    completing the free trial curriculum?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>No.</li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What projects will I work on in the Backend and Full Stack
                    Developer Programs?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          Details of all the projects you will work on in each
                          program are listed in the following pages:
                          <ul className="pl-2">
                            <li>
                              Backend :{' '}
                              <a
                                href="https://www.crio.do/backend-developer-track"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://www.crio.do/backend-developer-track
                              </a>
                            </li>
                            <li>
                              Full Stack :{' '}
                              <a
                                href="https://www.crio.do/full-stack-developer-track"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://www.crio.do/full-stack-developer-track
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What is the duration of Crio’s signature learning programs?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          Our signature learning programs are self-paced. You
                          will have access to all the instructional content and
                          learning resources indefinitely.
                        </li>
                        <li>
                          It can take anywhere from 3-6 months to complete the
                          program depending on how much time you are willing to
                          commit on a weekly basis.
                        </li>
                        <li>
                          To complete the hands-on exercises, we provide an
                          online IDE (Crio Workspace) for a total of 6 months
                          from your program start date. You have the option of
                          renewing access to the Crio Workspace on a
                          month-to-month basis after the 6-month period.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How will the program be delivered?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          It is a self-paced program with live workshops on key
                          concepts. Recordings of live sessions will be provided
                          if you are unable to attend.
                        </li>
                        <li>
                          Our customer success team will help you set weekly
                          goals (sprint plans) and hold you accountable to your
                          plans. A weekly report that summarizes your progress
                          and gives you a glimpse into the cohort progress helps
                          you stay on track.
                        </li>
                        <li>
                          A team of qualified mentors and teaching assistants
                          are available everyday to address project-related
                          queries and help you understand difficult concepts if
                          any.
                        </li>
                        <li>
                          We also have a lively community of driven and
                          like-minded individuals going through the program
                          along with you.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>{' '}
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What will I get when I graduate from Crio’s Signature
                    Learning Programs?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          You will get a verified Github portfolio of all the
                          projects you have completed.
                        </li>
                        <li>
                          You get to add 5+ projects on your resume and
                          confidently talk about them with current or interviews
                          with prospective employers
                        </li>
                        <li>
                          You will also get access to Crio’s Career Pack which
                          contains the following:
                          <ol type="a" className="pl-2">
                            <li>
                              A curated problem set with accompanying videos to
                              help you practice data structures, algorithms and
                              low-level design problems.
                            </li>
                            <li>
                              A review of your professional profile (resume and
                              LinkedIn) by Crio staff.
                            </li>
                          </ol>
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 mb-10 p-0">
        <h5>Payment & EMI Plans</h5>
        <div className="col-12 flex w-full flex-col p-0 md:w-11/12">
          <div className="">
            <div className={`${styles.accordion} custom mb-4`}>
              <ul>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How do I pay the program fee to enroll into Crio’s signature
                    learning programs?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          After you complete the free trial, we will send you an
                          email with the program details, fees and scholarship
                          details.
                        </li>
                        <li>
                          This email will contain a payment link that you can
                          use to submit your payment securely.
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    Are there EMI options available?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      <ul>
                        <li>
                          Yes, we have 2 EMI options available to you:
                          <ol type="a" className="pl-2">
                            <li>
                              Credit Card Based - If you have credit card you
                              can avail this
                            </li>
                            <li>
                              Debit Card Based - We have partnered with
                              Zest/Eduvanz to facilitate this (details will be
                              shared at the time of payment).
                            </li>
                          </ol>
                        </li>

                        <li>Both EMI options have 0% interest charges.</li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    What is the duration of EMI?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      12 Months for Signature tracks and 18 Months for
                      Fellowship/Masters Program, 0% Interest charges
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    Is there a way to get additional scholarship or discount
                    towards the program fee?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      We offer a referral which works as follows:
                      <ul>
                        <li>
                          Refer your peers to Crio’s signature learning tracks
                        </li>
                        <li>
                          If they enroll into the program and make a payment,
                          both of you will be eligible for an additional 10%
                          (upto Rs 3,000) discount on the program fee (voucher
                          or Amazon gift card).
                        </li>
                      </ul>
                    </div>
                  </p>
                </li>
                <li>
                  <input type="checkbox" defaultChecked />
                  <i />
                  <div className="subtitle1 pr-5 font-light">
                    How can I refer my friend to get an additional discount?
                  </div>
                  <p>
                    <div className={`${styles.accordionContent} p-3`}>
                      {' '}
                      Please write to us at{' '}
                      <a
                        href="mailto:referrals@criodo.com?subject=Refer a Friend to Crio Programs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        referrals@criodo.com
                      </a>{' '}
                      for details.
                    </div>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
