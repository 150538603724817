// extracted by mini-css-extract-plugin
export var circleArrow = "Registered-module--circleArrow--+E7P1";
export var circleImage = "Registered-module--circleImage--ew7+w";
export var customFont = "Registered-module--customFont--RrrPs";
export var heading = "Registered-module--heading--KEq0D";
export var list = "Registered-module--list--eGclS";
export var listBreak = "Registered-module--listBreak--bAb0S";
export var pill = "Registered-module--pill--ssgkO";
export var sketch = "Registered-module--sketch--m14bg";
export var sketchParent = "Registered-module--sketchParent--mIG1r";
export var skills = "Registered-module--skills--3XOWA";
export var testimonialCard = "Registered-module--testimonialCard--auEdX";
export var testimonialParent = "Registered-module--testimonialParent--916yG";
export var userImage = "Registered-module--userImage--sspmf";
export var whyCrioImage = "Registered-module--whyCrioImage--vAKxZ";